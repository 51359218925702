import { Icon } from "@iconify/react";
import Image from "next/image";
import Link from "next/link";
import Router from "next/router";
import Footer from "../components/Footer";
import GuestLayout from "../components/Layouts/GuestLayout";
import FiveHundredError from "./500";

function FourOhFourError() {
  return (
    <>
      <header
        className={`
          z-10
          flex
          sticky
          top-0
          justify-between
          px-6
          sm:px-10
          w-full
          h-20
          leading-5
          text-black
          items-center
          bg-white
          border-b-4
          border-teal-600
          border-solid
          box-border
        `}
      >
        <Link href="/hello">
          <a>
            <Image
              src="/images/equal-care-logo.webp"
              alt="Equal Care Co-op"
              title="The Equal Care Co-op Logo"
              className="p-2 h-20"
              height={60}
              width={90}
            />
          </a>
        </Link>
      </header>
      <main id="main" className="flex flex-col items-center min-h-screen">
        <section className="min-w-[300px] max-w-screen-xl py-12 px-4 lg:p-24 sm:pb-24 flex flex-col gap-12">
          <h1 className="text-3xl">Not found.</h1>
          <ul className="list list-inside list-disc">
            We couldn&apos;t find the page you asked for. This might be because:
            <br />
            <li>The URL has a typo in it</li>
            <li>Someone sent you a link to a page that does not exist</li>
            <li>You&apos;re not allowed to see the page</li>
          </ul>
          <ul className="list list-inside list-disc">
            You could try:
            <br />
            <li>
              Going{" "}
              <a
                onClick={() => Router.back()}
                className="underline cursor-pointer"
              >
                back to the last page
              </a>
            </li>
            <li>
              Heading to the{" "}
              <Link href="/dashboard">
                <a className="underline">dashboard</a>
              </Link>{" "}
              and finding your way from there
            </li>
            <li>Double-checking any links you have been sent</li>
          </ul>
        </section>
        <section className="bg-white h-12"></section>
      </main>
      <Footer />
    </>
  );
}

FourOhFourError.getLayout = GuestLayout;

export default FourOhFourError;
